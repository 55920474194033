import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors
} from 'ui/common/constants';

export const Link = styled.a`

  cursor: pointer;
  font-size: 0.85rem;
  line-height: 1.3rem;
  transition: color 0.2s ease-out;
  
  color: ${props => props.color};
  
  &:hover {
    color: ${props => props.colorHover};
  }
`;

Link.displayName = 'Link';

Link.propTypes = {
  color: PropTypes.string,
  colorHover: PropTypes.string,
};

Link.defaultProps = {
  color: Colors.White,
  colorHover: Colors.Blue,
};
