import {
  Environment
} from 'common';

import {
  IdentityApi
} from 'api/identity';

export const FetchApi = {

  send: async (
    url, {
      method = 'GET',
      headers = {},
      body = {},
      query = {},
      path = {},
    } = {}
  ) => {

    for (const [key, val] of Object.entries(path)) {

      const regex = new RegExp(`:${key}|{${key}}`, 'g');
      url = url.replace(regex, val);
    }

    if (query && Object.keys(query).length > 0) {

      url += '?';

      for (const [key, value] of Object.entries(query)) {
        url += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
      }
    }

    const session = await IdentityApi.getSession();

    if (session && session.isValid()) {

      let accessToken = session
        .getAccessToken()
        .getJwtToken();

      headers['Authorization'] = `Bearer ${accessToken}`;
    }

    !headers['content-type'] && (headers['content-type'] = 'application/json');

    body = (method !== 'GET' && JSON.stringify(body)) || undefined;
    url = (url.indexOf('http') < 0 && `${Environment.apiBase}/${url}`) || url;

    let res = await fetch(
      url, {
        method,
        headers,
        body,
      }
    );

    let contentType = res.headers.get('content-type');
    let isJson = typeof contentType === 'string' && contentType.split(';')[0].trim() === 'application/json';

    // Changed this to a try swallow because the backend at the moment send content-type json but does not send a response
    // Don't do this without a very good reason!
    let ret = await res.text();

    try {
      ret = (isJson && JSON.parse(ret)) || ret;
    } catch {}

    if (!res.ok) {
      throw Error(ret.message || ret || 'Request failed');
    }

    return ret;
  },
};
