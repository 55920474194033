import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  SideBarItem,
  Card
} from 'ui/common/components';

const Container = styled(Card)`
  flex-direction: column;
`;

export const SideBar = props => {
  // TODO Convert to UseRecoilState - need to keep track of selected item accross all scenes
  const [activeItem, setActiveItem] = React.useState(props.items[0])

  const onItemClick = item => {
    setActiveItem(item);
  };

  return (
    <Container>
      {!!props.items && !!props.items.length &&
        props.items.map((item, index) => {
          return (
            <SideBarItem
              isSelected={activeItem && activeItem.key === item.key}
              key={`side-bar-item-${item.key}`}
              item={item}
              onItemClick={onItemClick}
            ></SideBarItem>
          );
        })
      }
    </Container>
  );

};

SideBar.displayName = 'SideBar';

SideBar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired
  })).isRequired,
};

SideBar.defaultProps = {
  items: undefined,
};