import {
  IdentityPaths,
  IdentityRoutes,
  LandingPaths,
  LandingRoutes,
} from 'ui/navigation/routes';

export * from 'ui/navigation/constants';
export * from 'ui/navigation/Navigator';
export * from 'ui/navigation/utilities';

export const Routes = Object.assign(
  {},
  IdentityRoutes,
  LandingRoutes,
);

export const Paths = Object.assign(
  {},
  IdentityPaths,
  LandingPaths,
);
