import {
  FetchApi
} from 'api/fetch';

export const MembersApi = {

  getSystemCredentials: async memberId => {

    if (!memberId) {
      throw Error('Unable to get member credentials for an invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/syscreds', {
        path: {
          memberId,
        }
      }
    );
  },

  updateUsername: async (
    memberId,
    username,
  ) => {

    if (!memberId) {
      throw Error('Unable to get member credentials for an invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/username', {
        method: 'PUT',
        body: {
          username,
        },
        path: {
          memberId
        },
      }
    );
  },
};
