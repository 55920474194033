export const Colors = {
  Transparent: '#00000000',
  BlackTransparent10: '#23222310',
  Black: '#232223',
  Grey: '#595959',
  White: '#d0d0d0',
  LighterBlue: '#196aba',
  BlueTransparent30: '#0056b330',
  Blue: '#0056b3',
  Red: '#dc3545',
  Yellow: '#ffc107'
};

export const Shadows = {
  NoOffsetSlightGradientTransparentBlack: `0 1px 15px ${Colors.BlackTransparent10}, 0 1px 8px ${Colors.BlackTransparent10}`,
  BasicShadow: `0 1px 15px rgba(0,0,0,.1), 0 1px 8px rgba(0,0,0,.1)`
};

export const Images = {
  Backgrounds: {
    Blue: '/assets/images/backgrounds/blue.jpg',
    Black: '/assets/images/backgrounds/black.jpg',
  },
  Logos: {
    Footprint: '/assets/images/logos/footprint.svg',
    FootprintType: '/assets/images/logos/footprint-type.svg',
  },
  Icons: {
    Avatar: '/assets/images/icons/avatar.svg',
    MenuIconSmall: '/assets/images/icons/menu_icon_small.svg',
    MenuIconLarge: '/assets/images/icons/menu_icon_large.svg',
  }
};

export const Icons = {
  User: 'e005',
  People: 'e001',
  UserFemale: 'e000',
  UserFollow: 'e002',
  UserFollowing: 'e003',
  UserUnfollow: 'e004',
  Login: 'e066',
  Logout: 'e065',
  Emotsmile: 'e021',
  Phone: 'e600',
  CallEnd: 'e048',
  CallIn: 'e047',
  CallOut: 'e046',
  Map: 'e033',
  LocationPin: 'e096',
  Direction: 'e042',
  Directions: 'e041',
  Compass: 'e045',
  Layers: 'e034',
  Menu: 'e601',
  List: 'e067',
  OptionsVertical: 'e602',
  Options: 'e603',
  ArrowDown: 'e604',
  ArrowLeft: 'e605',
  ArrowRight: 'e606',
  ArrowUp: 'e607',
  ArrowUpCircle: 'e078',
  ArrowLeftCircle: 'e07a',
  ArrowRightCircle: 'e079',
  ArrowDownCircle: 'e07b',
  Check: 'e080',
  Clock: 'e081',
  Plus: 'e095',
  Minus: 'e615',
  Close: 'e082',
  Event: 'e619',
  Exclamation: 'e617',
  Organization: 'e616',
  Trophy: 'e006',
  ScreenSmartphone: 'e010',
  ScreenDesktop: 'e011',
  Plane: 'e012',
  Notebook: 'e013',
  Mustache: 'e014',
  Mouse: 'e015',
  Magnet: 'e016',
  Energy: 'e020',
  Disc: 'e022',
  Cursor: 'e06e',
  CursorMove: 'e023',
  Crop: 'e024',
  Chemistry: 'e026',
  Speedometer: 'e007',
  Shield: 'e00e',
  ScreenTablet: 'e00f',
  MagicWand: 'e017',
  Hourglass: 'e018',
  Graduation: 'e019',
  Ghost: 'e01a',
  GameController: 'e01b',
  Fire: 'e01c',
  Eyeglass: 'e01d',
  EnvelopeOpen: 'e01e',
  EnvelopeLetter: 'e01f',
  Bell: 'e027',
  Badge: 'e028',
  Anchor: 'e029',
  Wallet: 'e02a',
  Vector: 'e02b',
  Speech: 'e02c',
  Puzzle: 'e02d',
  Printer: 'e02e',
  Present: 'e02f',
  Playlist: 'e030',
  Pin: 'e031',
  Picture: 'e032',
  Handbag: 'e035',
  GlobeAlt: 'e036',
  Globe: 'e037',
  FolderAlt: 'e039',
  Folder: 'e089',
  Film: 'e03a',
  Feed: 'e03b',
  Drop: 'e03e',
  Drawer: 'e03f',
  Docs: 'e040',
  Doc: 'e085',
  Diamond: 'e043',
  Cup: 'e044',
  Calculator: 'e049',
  Bubbles: 'e04a',
  Briefcase: 'e04b',
  BookOpen: 'e04c',
  BasketLoaded: 'e04d',
  Basket: 'e04e',
  Bag: 'e04f',
  ActionUndo: 'e050',
  ActionRedo: 'e051',
  Wrench: 'e052',
  Umbrella: 'e053',
  Trash: 'e054',
  Tag: 'e055',
  Support: 'e056',
  Frame: 'e038',
  SizeFullscreen: 'e057',
  SizeActual: 'e058',
  Shuffle: 'e059',
  ShareAlt: 'e05a',
  Share: 'e05b',
  Rocket: 'e05c',
  Question: 'e05d',
  PieChart: 'e05e',
  Pencil: 'e05f',
  Note: 'e060',
  Loop: 'e064',
  Home: 'e069',
  Grid: 'e06a',
  Graph: 'e06b',
  Microphone: 'e063',
  MusicToneAlt: 'e061',
  MusicTone: 'e062',
  EarphonesAlt: 'e03c',
  Earphones: 'e03d',
  Equalizer: 'e06c',
  Like: 'e068',
  Dislike: 'e06d',
  ControlStart: 'e06f',
  ControlRewind: 'e070',
  ControlPlay: 'e071',
  ControlPause: 'e072',
  ControlForward: 'e073',
  ControlEnd: 'e074',
  Volume1: 'e09f',
  Volume2: 'e0a0',
  VolumeOff: 'e0a1',
  Calendar: 'e075',
  Bulb: 'e076',
  Chart: 'e077',
  Ban: 'e07c',
  Bubble: 'e07d',
  Camrecorder: 'e07e',
  Camera: 'e07f',
  CloudDownload: 'e083',
  CloudUpload: 'e084',
  Envelope: 'e086',
  Eye: 'e087',
  Flag: 'e088',
  Heart: 'e08a',
  Info: 'e08b',
  Key: 'e08c',
  Link: 'e08d',
  Lock: 'e08e',
  LockOpen: 'e08f',
  Magnifier: 'e090',
  MagnifierAdd: 'e091',
  MagnifierRemove: 'e092',
  PaperClip: 'e093',
  PaperPlane: 'e094',
  Power: 'e097',
  Refresh: 'e098',
  Reload: 'e099',
  Settings: 'e09a',
  Star: 'e09b',
  SymbolFemale: 'e09c',
  SymbolMale: 'e09d',
  Target: 'e09e',
  CreditCard: 'e025',
  Paypal: 'e608',
  SocialTumblr: 'e00a',
  SocialTwitter: 'e009',
  SocialFacebook: 'e00b',
  SocialInstagram: 'e609',
  SocialLinkedin: 'e60a',
  SocialPinterest: 'e60b',
  SocialGithub: 'e60c',
  SocialGoogle: 'e60d',
  SocialReddit: 'e60e',
  SocialSkype: 'e60f',
  SocialDribbble: 'e00d',
  SocialBehance: 'e610',
  SocialFoursqare: 'e611',
  SocialSoundcloud: 'e612',
  SocialSpotify: 'e613',
  SocialStumbleupon: 'e614',
  SocialYoutube: 'e008',
  SocialDropbox: 'e00c',
  SocialVkontakte: 'e618',
  SocialSteam: 'e620'
}