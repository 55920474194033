import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  HContainerV,
  HFillContainerVH,
} from 'ui/common/components/Containers';

import {
  Text,
  FootprintTypeLogo,
} from 'ui/common/components';

import {
  Colors,
  Images,
  Shadows
} from 'ui/common/constants';


const Container = styled(HFillContainerVH)`
  background-color: ${Colors.Black};
  box-shadow: ${Shadows.NoOffsetSlightGradientTransparentBlack};
  justify-content: space-between;
  padding-left: 3em;
  padding-right: 3em;
`;

const MenuIcon = styled.img`
  height: 1em;
  cursor: pointer;
`;

const Image = styled.img`
  height: 3em;
  width: 3em;
  border-radius: 5em;
  cursor: pointer;
  margin-left: 1em;
`;


export const TopBar = props => {

  return (

    <Container {...props}>

      <HContainerV>

        <MenuIcon
          src={Images.Icons.MenuIconSmall}
          onClick={props.onMenuButtonClick} />

        <MenuIcon
          src={Images.Icons.MenuIconLarge}
          onClick={props.onMenuButtonClick} />

      </HContainerV>

      <FootprintTypeLogo
        height={'2em'}
        flex={1} />

      <HContainerV>

        <Text>
          {props.username}
        </Text>

        <Image
          src={props.avatarSrc || Images.Icons.Avatar}
          alt={'avatar icon'}
          onClick={props.onAvatarClick} />

      </HContainerV>

    </Container>
  )
};

TopBar.displayName = 'TopBar';

TopBar.propTypes = {
  username: PropTypes.string,
  avatarSrc: PropTypes.string,
  onMenuButtonClick: PropTypes.func,
  onAvatarClick: PropTypes.func,
};

TopBar.defaultProps = {
  username: '',
  avatarSrc: '',
  onMenuButtonClick: () => { },
  onAvatarClick: () => { }
};
