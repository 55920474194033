import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Images
} from 'ui/common/constants';

const Image = styled.img`
  height: ${props => props.height || 'auto'};
  width: ${props => props.width || 'auto'};
  flex: ${props => props.flex || 1}; 
`;

export const FootprintTypeLogo = props =>

  <Image
    {...props}
    src={Images.Logos.FootprintType}
    alt={'Footprint logo with type'}/>
;

FootprintTypeLogo.displayName = 'FootprintTypeLogo';

FootprintTypeLogo.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

FootprintTypeLogo.defaultProps = {
  height: 'auto',
  width: 'auto',
};
