import {
  withLazy,
} from 'ui/common';

const dImport = import('ui/landing');

export const LandingPaths = {
  Landing: '/landing',
};

export const LandingRoutes = {
  landing: {
    component: withLazy(
      dImport,
      'Landing'
    ),
    path: LandingPaths.Landing,
  },
};
