import 
	React 
from 'react';

import 
	styled 
from 'styled-components/macro';

import 
	PropTypes 
from 'prop-types';

import {
	Heading6
} from 'ui/common/components/Type';

import {
	VFillContainerVH,
	VFillContainer
} from 'ui/common/components/Containers'

import {
	Colors,
} from 'ui/common/constants';

import {
	SimpleIcon
} from 'ui/common/components/SimpleIcon';

const Container = styled(VFillContainerVH)`
  padding: 0.5em 1.2em 0.5em 1.2em;
  height: 5vh;
  min-height: 5em;
  max-height: 7em;
  border-bottom: 0.1em solid ${Colors.Grey};
  cursor: pointer;
  
  &:hover ${SimpleIcon}::before, 
  &:hover ${Heading6} {
    color: ${Colors.LighterBlue};
  }
`;

const ActiveItemIndicator = styled(VFillContainer)`
  left: 0;
  width: 2vw;
  position: absolute;
  border-left: 0.25em solid ${Colors.LighterBlue};
  height: 10vh;
  min-height: 5em;
  max-height: 7em;
`;

export const SideBarItem = props =>
	<Container onClick={() => { props.onItemClick(props.item) }}>
		{!!props.isSelected &&
			<ActiveItemIndicator />
		}
		{!!props.item.icon &&
			<SimpleIcon fontCharacter={props.item.icon} />
		}
		<Heading6>{props.item.display}</Heading6>
	</Container>
	;

SideBarItem.propTypes = {
	display: PropTypes.string.isRequired,
	route: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired
}

SideBarItem.defaultProps = {
	display: '',
	route: '',
	icon: '',
}

SideBarItem.displayName = 'SideBarItem';