export const getPath = (
  path,
  parameters = {}
) => {

  if (typeof path !== 'string') {
    return path;
  }

  for (const [key, val] of Object.entries(parameters)) {

    const regex = new RegExp(`:${key}|{${key}}`, 'g');
    path = path.replace(regex, val);
  }

  return path;
};
