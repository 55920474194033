import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors,
  Shadows,
} from 'ui/common/constants';

import {
  BounceIndicator
} from 'ui/common/components/BounceIndicator';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.75rem 3rem;
  height: 1.5rem;
  line-height: 1.5;
  font-weight: 700;
  transition: background-color 0.2s ease-out;
  
  width: ${props => props.width};
  
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};
  
  color: ${props => (props.disabled && props.colorDisabled) || props.color};
  background-color: ${props => (props.disabled && props.backgroundColorDisabled) || props.backgroundColor};
  
  &:hover {
    color: ${props => (props.disabled && props.colorDisabled) ||props.colorHover};
    background-color: ${props => (props.disabled && props.backgroundColorDisabled) || props.backgroundColorHover};
  }
`;

export const Button = props =>

  <Container
    {...props}
    onClick={(!props.disabled && !props.busy && props.onClick) || undefined}>

    {!props.busy && props.text}

    { !!props.busy &&

      <BounceIndicator/>
    }

  </Container>
;

Button.displayName = 'Button';

Button.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  busy: PropTypes.bool,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  colorDisabled: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundColorHover: PropTypes.string,
  backgroundColorDisabled: PropTypes.string,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  boxShadow: PropTypes.string,
  width: PropTypes.string,
};

Button.defaultProps = {
  text: '',
  disabled: false,
  color: Colors.White,
  colorHover: Colors.White,
  colorDisabled: Colors.White,
  backgroundColor: Colors.Blue,
  backgroundColorHover: Colors.LighterBlue,
  backgroundColorDisabled: Colors.Grey,
  border: undefined,
  borderRadius: '2rem',
  boxShadow: Shadows.NoOffsetSlightGradientTransparentBlack,
  width: '3.5rem',
};
