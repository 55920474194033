import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

const BaseContainer = styled.div`
  display: flex;
`;

// !!WARNING!! USE THIS SPARINGLY, STYLED-COMPONENTS WILL CREATE NEW CSS CLASSES FOR EVERY PROP VARIATION!
//             RATHER DERIVE OR USE A COMBINATION OF THE OTHER CONTAINERS IN THIS FILE
export const Container = styled(BaseContainer).attrs(props => ({
  id: props.id,
  style: {
    width: props.width,
    height: props.height,
  },
}))`
  ${props => props.flex && `flex: ${props.flex};`}
  ${props => props.flexFlow && `flex-flow: ${props.flexFlow};`}
  ${props => props.flexDirection && `flex-direction: ${props.flexDirection};`}
  ${props => props.flexWrap && `flex-wrap: ${props.flexWrap};`}
  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  ${props => props.alignContent && `align-content: ${props.alignContent};`}
  ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
`;

Container.propTypes = {
  flex: PropTypes.string,
  flexFlow: PropTypes.string,
  flexDirection: PropTypes.string,
  flexWrap: PropTypes.string,
  alignItems: PropTypes.string,
  alignContent: PropTypes.string,
  justifyContent: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export const HContainer = styled(BaseContainer)`
  flex-direction: row;
`;

export const HContainerV = styled(HContainer)`
  align-items: center;
`;

export const HContainerH = styled(HContainer)`
  justify-content: center;
`;

export const HContainerVH = styled(HContainer)`
  align-items: center;
  justify-content: center;
`;

export const HFillContainer = styled(HContainer)`
  flex: ${props => props.flex || '1 0 auto'};
`;

export const HFillContainerV = styled(HFillContainer)`
  align-items: center;
`;

export const HFillContainerH = styled(HFillContainer)`
  justify-content: center;
`;

export const HFillContainerVH = styled(HFillContainer)`
  align-items: center;
  justify-content: center;
`;

export const VContainer = styled(BaseContainer)`
  flex-direction: column;
`;

export const VContainerV = styled(VContainer)`
  justify-content: center;
`;

export const VContainerH = styled(VContainer)`
  align-items: center;
`;

export const VContainerVH = styled(VContainer)`
  align-items: center;
  justify-content: center;
`;

export const VFillContainer = styled(VContainer)`
  flex: ${props => props.flex || '1 0 auto'};
`;

export const VFillContainerV = styled(VFillContainer)`
  justify-content: center;
`;

export const VFillContainerH = styled(VFillContainer)`
  align-items: center;
`;

export const VFillContainerVH = styled(VFillContainer)`
  align-items: center;
  justify-content: center;
`;
