import
  React
from 'react';

import {
  RecoilRoot
} from 'recoil';

import {
  Navigator,
  Routes,
  NavigatorType,
} from 'ui/navigation';

import {
  Toast
} from 'ui/common';

import {
  IdentityAtom,
} from 'ui/atoms';

import {
  createGlobalStyle
} from "styled-components";

import {
  IdentityApi
} from 'api';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'simple-line-icons';
    src: 
      url("/assets/fonts/Simple-Line-Icons.eot") format("embedded-opentype"),
      url("/assets/fonts/Simple-Line-Icons.svg") format("svg"),
      url("/assets/fonts/Simple-Line-Icons.ttf") format("truetype"),
      url("/assets/fonts/Simple-Line-Icons.woff") format("woff"),
      url("/assets/fonts/Simple-Line-Icons.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }
`

const setInitialAtoms = ({
  set
}) => {

  const user = IdentityApi.getUsername();

  !!user && set(
    IdentityAtom, {
      user,
    },
  );
};

const App = () =>

  <React.StrictMode>

    <RecoilRoot
      initializeState={setInitialAtoms}>

      <Navigator
        id={'app-navigator'}
        base={'/'}
        routes={Routes}
        type={NavigatorType.Browser} />

      <GlobalStyle />

      <Toast />

    </RecoilRoot>

  </React.StrictMode>
  ;

App.displayName = 'App';

export default App;
