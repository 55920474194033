import
  React
from 'react';

import {
  Spinner,
} from 'ui/common/components';

export const withLazy = (
  dynamicImport,
  componentName,
) => {

  return class WithLazyLoad extends React.Component {

    state = {
      component: null
    };

    componentDidMount = () => {

      if (!dynamicImport) {
        return;
      }

      dynamicImport
        .then(module => {

          this.setState({
            component: module[componentName]
          });
        })
        .catch(e => console.error(e));
    };

    render = () => {

      const ImportedComponent = this.state.component;

      return !!ImportedComponent

        ? <ImportedComponent {...this.props}/>
        : <Spinner/>
    };
  };
};
