import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

const Container = styled.div``;

export const Footer = props =>

  <Container {...props}>
  </Container>
;

Footer.displayName = 'Footer';

Footer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    route: PropTypes.string,
  })).isRequired,
};

Footer.defaultProps = {
  items: undefined,
};
