import {
  ConfirmPassword,
  Forgot,
  Login,
  NewPassword,
  NewUsername,
} from 'ui/identity';

export const IdentityPaths = {
  ConfirmPassword: '/identity/confirm-password',
  Login: '/identity/login',
  Forgot: '/identity/forgot',
  NewPassword: '/identity/new-password',
  NewUsername: '/identity/new-username',
};

export const IdentityRoutes = {
  confirmPassword: {
    component: ConfirmPassword,
    path: IdentityPaths.ConfirmPassword,
    excludeAuth: true,
  },
  login: {
    component: Login,
    path: IdentityPaths.Login,
    default: true,
    excludeAuth: true,
  },
  forgot: {
    component: Forgot,
    path: IdentityPaths.Forgot,
    excludeAuth: true,
  },
  newPassword: {
    component: NewPassword,
    path: IdentityPaths.NewPassword,
    excludeAuth: true,
  },
  newUsername: {
    component: NewUsername,
    path: IdentityPaths.NewUsername,
  },
};
