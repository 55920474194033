import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Images
} from 'ui/common/constants';

const Image = styled.img`
  height: ${props => props.height || 'auto'};
  width: ${props => props.width || 'auto'};
`;

export const FootprintLogo = props =>

  <Image
    {...props}
    src={Images.Logos.Footprint}
    alt={'Footprint logo'}/>
;

FootprintLogo.displayName = 'FootprintLogo';

FootprintLogo.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

FootprintLogo.defaultProps = {
  height: 'auto',
  width: 'auto',
};
