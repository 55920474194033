import
  React
from 'react';

import {
  useSetRecoilState,
} from 'recoil';

import {
  Validation,
} from 'common';

import {
  InputTypes,
  VFillContainerV,
  Button,
  Input,
  Link,
  useShowErrorToast,
} from 'ui/common';

import {
  Paths
} from 'ui/navigation';

import {
  ActionContainer,
  IdentityScene,
} from 'ui/identity/components';

import {
  IdentityAtom,
} from 'ui/atoms';

import {
  IdentityStatus,
  IdentityApi,
  MembersApi,
} from 'api';

export const Login = props => {

  const setIdentity = useSetRecoilState(IdentityAtom);

  const [
    state,
    setState,
  ] = React.useState({
    user: '',
    password: '',
    isValid: false,
    isBusy: false,
  });

  const showErrorToast = useShowErrorToast('Login');

  React.useEffect(() => {

    IdentityApi
      .refresh()
      .then(() => {
        props.history.push(Paths.Landing);
      })
      .catch(e => e);

  }, [
    props.history,
  ]);

  const onInputChange = e => {

    if (!e || e.defaultPrevented) {
      return;
    }

    e.preventDefault();

    let inputs = {
      'login-user': 'user',
      'login-password': 'password',
    };

    const key = inputs[e.target.id];
    const value = e.target.value;

    setState(prevState => {

      let state = {
        ...prevState,
        [key]: value,
      };

      return ({
        ...state,
        isValid: Validation.isValidUserName(state.user) && Validation.isValidPassword(state.password),
      });
    });
  };

  const onLogin = () => {

    if (!state.isValid || state.isBusy) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      isBusy: true,
    }));

    IdentityApi
      .login(
        state.user,
        state.password,
      )
      .then(identity => {

        setIdentity(identity);

        if (identity.status === IdentityStatus.NewPassword) {

          setState(prevState => ({
            ...prevState,
            password: '',
            isBusy: false,
          }));

          props.history.push(Paths.NewPassword);
          return;
        }

        MembersApi
          .getSystemCredentials(identity.memberId)
          .then(credentials => {

            if (!credentials) {
              return;
            }

            setState(prevState => ({
              ...prevState,
              password: '',
              isBusy: false,
            }));

            const newUser = Array.isArray(credentials) && credentials.length && credentials[0] && credentials[0] && !credentials[0].CognitoUsernameChangeDate;
            props.history.push((newUser && Paths.NewUsername) || Paths.Landing);

          })
          .catch(() => props.history.push(Paths.Landing));

      })
      .catch(e => {

        setState(prevState => ({
          ...prevState,
          isBusy: false,
        }));

        showErrorToast(e);
      });
  };

  return (

    <IdentityScene
      headline={'LEAVING A FOOTPRINT FOR OTHERS TO FOLLOW'}
      subtext={'Please use your credentials to log in.'}
      formHeading={'Login'}>

      <VFillContainerV>

        <Input
          id={'login-user'}
          name={'login-user'}
          key={'login-user'}
          type={InputTypes.Text}
          readOnly={state.isBusy}
          label={'Username'}
          value={state.user}
          onChange={onInputChange}/>

        <Input
          id={'login-password'}
          name={'login-password'}
          key={'login-password'}
          type={InputTypes.Password}
          readOnly={state.isBusy}
          label={'Password'}
          value={state.password}
          onChange={onInputChange}/>

      </VFillContainerV>

      <ActionContainer>

        <Link
          onClick={() => props.history.push(Paths.Forgot)}>

          Forgot your password?
        </Link>

        <Button
          text={'LOGIN'}
          disabled={!state.isValid}
          busy={state.isBusy}
          onClick={onLogin}/>

      </ActionContainer>

    </IdentityScene>
  );
};

Login.displayName = 'Login';
