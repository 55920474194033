import
  React
from 'react';

import {
  useRecoilValue
} from 'recoil';

import {
  VFillContainer,
  HFillContainer,
} from 'ui/common/components/Containers'

import {
  Icons
} from 'ui/common/constants';

import {
  Footer,
  SideBar,
  TopBar,
} from 'ui/common/components';

import {
  IdentityAtom
} from 'ui/atoms'

export const Scene = props => {

  const identity = useRecoilValue(IdentityAtom);
  return (
    <VFillContainer>
    
      <TopBar 
        username={identity.user} 
      />

      <HFillContainer
        flex={9} >

        <SideBar items={[
          {
            key: 'insight',
            display: 'inSight',
            route: '/insight',
            icon: Icons.Eye
          },
          {
            key: 'members',
            display: 'Members',
            route: '/members',
            icon: Icons.People
          },
          {
            key: 'organisations',
            display: 'Organisations',
            route: '/organisations',
            icon: Icons.Globe
          },
          {
            key: 'tournaments',
            display: 'Tournaments',
            route: '/tournaments',
            icon: Icons.Trophy
          },
          {
            key: 'squads',
            display: 'Squads',
            route: '/squads',
            icon: Icons.People
          },
          {
            key: 'medical',
            display: 'Medical',
            route: '/medical',
            icon: Icons.Heart
          },
          {
            key: 'settings',
            display: 'Settings',
            route: '/settings',
            icon: Icons.Settings
          }
        ]} />
        {props.children}

        <Footer items={[
          {
            key: 'footerOne',
            display: 'FooterDisplayOne',
            route: '/insight',
            icon: '/assets/icons/icon1.svg'
          }
        ]} />

      </HFillContainer>
    
    </VFillContainer>
  );
};

Scene.displayName = 'Scene';
