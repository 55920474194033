import
  styled
from 'styled-components/macro';

import {
  HFillContainerV
} from 'ui/common';

export const ActionContainer = styled(HFillContainerV)`
  padding: 0.75rem 0 0 0;
  justify-content: ${props => props.justifyContent || 'space-between'};
`;
