import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors,
} from 'ui/common/constants';

export const InputTypes = {
  Text: 'text',
  CheckBox: 'checkbox',
  Color: 'color',
  Date: 'date',
  Email: 'email',
  File: 'file',
  Number: 'number',
  Password: 'password',
};

const Container = styled.div`
  display: flex;
  position: relative;
  
  width: ${props => props.width};
  min-width: ${props => props.minWidth};
  height: ${props => props.height};
  min-height: ${props => props.minHeight};
`;

const InnerInput = styled.input`
  flex: 1;
  padding: 0.75rem;
  margin: 0.5rem 0;
  color: ${Colors.White};
  background-color: transparent;
  border: 1px solid #424242;
  border-radius: 0.2vmin;
  
  :focus {
    outline: none;
    border: ${`1px solid ${Colors.LighterBlue}`};
  }
  
  :-webkit-autofill {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }
  :-moz-autofill {
    -moz-transition-delay: 9999s;
    transition-delay: 9999s;
  }
  :-o-autofill {
    -o-transition-delay: 9999s;
    transition-delay: 9999s;
  }
  :-khtml-autofill {
    -khtml-transition-delay: 9999s;
    transition-delay: 9999s;
  }
`;

const Label = styled.div`
  position: absolute;
  top: 0.12rem;
  left: 0.5rem;
  font-size: 0.7rem;
  color: ${Colors.White};
`;

export const Input = props => {

  return (

    <Container
      {...props}>

      <InnerInput
        {...props}
        onChange={props.onChange}/>

      { !!props.label &&

        <Label>
          {props.label}
        </Label>
      }

    </Container>
  )
};

Input.displayName = 'Input';

Input.propTypes = {
  type: PropTypes.oneOf([
    InputTypes.Text,
    InputTypes.CheckBox,
    InputTypes.Color,
    InputTypes.Date,
    InputTypes.Email,
    InputTypes.File,
    InputTypes.Number,
    InputTypes.Password,
  ]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  autoFocus: PropTypes.bool,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  width: PropTypes.string,
  minWidth: PropTypes.string,
  height: PropTypes.string,
  minHeight: PropTypes.string,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  type: InputTypes.Text,
  placeholder: '',
  label: '',
  value: '',
  readOnly: undefined,
  disabled: false,
  autoFocus: false,
  maxLength: 200,
  width: 'auto',
  minWidth: '150px',
  height: 'auto',
  minHeight: 'auto',
  onChange: undefined,
};
