import
  React
from 'react';

import {
  useRecoilValue,
} from 'recoil';

import {
  Validation,
} from 'common';

import {
  InputTypes,
  VFillContainerV,
  Button,
  Input,
  useShowErrorToast,
} from 'ui/common';

import {
  Paths
} from 'ui/navigation';

import {
  ActionContainer,
  IdentityScene,
} from 'ui/identity/components';

import {
  IdentityAtom,
} from 'ui/atoms';

import {
  MembersApi
} from 'api';

export const NewUsername = props => {

  const identity = useRecoilValue(IdentityAtom);

  const [
    state,
    setState,
  ] = React.useState({
    user: '',
    isValid: false,
    isBusy: false,
  });

  const showErrorToast = useShowErrorToast('Change username');

  const onInputChange = e => {

    if (!e || e.defaultPrevented) {
      return;
    }

    e.preventDefault();

    const value = e.target.value;

    setState(prevState => ({
      ...prevState,
      user: value,
      isValid: Validation.isValidUserName(state.user),
    }));
  };

  const onChange = () => {

    if (!state.isValid || state.isBusy) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      isBusy: true,
    }));

    MembersApi
      .updateUsername(
        identity.memberId,
        state.user,
      )
      .then(() => {

        setState(prevState => ({
          ...prevState,
          user: '',
          isBusy: false,
        }));

        props.history.replace(Paths.Landing);
      })
      .catch(e => {

        setState(prevState => ({
          ...prevState,
          isBusy: false,
        }));

        showErrorToast(e);
      });
  };

  return (

    <IdentityScene
      headline={'LEAVING A FOOTPRINT FOR OTHERS TO FOLLOW'}
      subtext={'Please set a new username.'}
      formHeading={'New username'}>

      <VFillContainerV>

        <Input
          id={'new-username'}
          name={'new-username'}
          key={'new-username'}
          type={InputTypes.Text}
          readOnly={state.isBusy}
          label={'Username'}
          value={state.user}
          onChange={onInputChange}/>

      </VFillContainerV>

      <ActionContainer
        justifyContent={'flex-end'}>

        <Button
          text={'CHANGE'}
          disabled={!state.isValid}
          busy={state.isBusy}
          onClick={onChange}/>

      </ActionContainer>

    </IdentityScene>
  );
};

NewUsername.displayName = 'NewUsername';
