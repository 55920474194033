import
  React
from 'react';

import {
  useSetRecoilState
} from 'recoil';

import {
  Validation
} from 'common';

import {
  InputTypes,
  VFillContainerV,
  Button,
  Input,
  Link,
  useShowInfoToast,
  useShowErrorToast,
} from 'ui/common';

import {
  Paths
} from 'ui/navigation';

import {
  ActionContainer,
  IdentityScene,
} from 'ui/identity/components';

import {
  IdentityAtom,
} from 'ui/atoms';

import {
  IdentityApi
} from 'api';

export const Forgot = props => {

  const setIdentity = useSetRecoilState(IdentityAtom);

  const [
    state,
    setState,
  ] = React.useState({
    user: '',
    isValid: false,
    isBusy: false,
  });

  const showInfoToast = useShowInfoToast();
  const showErrorToast = useShowErrorToast('Forgot password');

  const onInputChange = e => {

    if (!e || e.defaultPrevented) {
      return;
    }

    e.preventDefault();

    const value = e.target.value;

    setState(prevState => {

      let state = {
        ...prevState,
        user: value,
      }

      return ({
        ...prevState,
        user: value,
        isValid: Validation.isValidUserName(state.user),
      });
    });
  };

  const onReset = () => {

    if (!state.isValid || state.isBusy) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      isBusy: true,
    }));

    IdentityApi
      .forgot(state.user)
      .then(identity => {

        setIdentity(identity);

        setState(prevState => ({
          ...prevState,
          isBusy: false,
        }));

        showInfoToast({
          heading: 'Verification code sent to',
          message: identity.destination,
        });

        props.history.push(Paths.ConfirmPassword);
      })
      .catch(e => {

        setState(prevState => ({
          ...prevState,
          isBusy: false,
        }));

        showErrorToast(e);
      });
  };

  return (

    <IdentityScene
      headline={'LEAVING A FOOTPRINT FOR OTHERS TO FOLLOW'}
      subtext={'Please use your username to reset your password.'}
      formHeading={'Forgot password'}>

      <VFillContainerV>

        <Input
          id={'forgot-user'}
          name={'forgot-user'}
          key={'forgot-user'}
          type={InputTypes.Text}
          readOnly={state.isBusy}
          label={'Username'}
          value={state.user}
          onChange={onInputChange}/>

      </VFillContainerV>

      <ActionContainer>

        <Link
          onClick={() => props.history.push(Paths.Login)}>

          Login
        </Link>

        <Button
          text={'SEND'}
          disabled={!state.isValid}
          busy={state.isBusy}
          onClick={onReset}/>

      </ActionContainer>

    </IdentityScene>
  );
};

Forgot.displayName = 'Forgot';
