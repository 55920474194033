export const useLog = ({
  name = 'unknown',
}) => {

  return {

    info: message => console.log({
      name,
      message
    }),

    error: error => console.error(
      error, {
        name,
        message: error.message
      }
    ),
  };
}
