const Environments = {
  local: {
    uiHost: 'localhost',
    apiBase: 'https://dev-api.reboot.footprintapp.net',
  },
  dev: {
    uiHost: 'dev.reboot.footprintapp.net',
    apiBase: 'https://dev-api.reboot.footprintapp.net',
  },
};

const currentHost = window?.location?.hostname?.toLowerCase?.();
let currentEnvironment = Environments.local;

for (const environment of Object.values(Environments)) {

  const envHost = currentEnvironment.uiHost.replace?.(/^https?:\/\//, '').replace(/:[0-9]+/, '');

  if (envHost === currentHost) {

    currentEnvironment = environment;
    break;
  }
}

export const Environment = currentEnvironment;
