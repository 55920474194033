import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  Shadows,
} from 'ui/common/constants';

const Container = styled.div`
  display: flex;
  background: ${Colors.Black};
  border-radius: 1vmin;
  box-shadow: ${Shadows.BasicShadow};
`;

export const Card = props =>

  <Container {...props}>
    {props.children}
  </Container>
  ;

Card.displayName = 'Card';

Card.defaultProps = {
  id: 'unknown-card',
};
