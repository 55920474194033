import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Images,
  Heading2,
  Heading6,
  Paragraph,
  VFillContainerVH,
  VFillContainerV,
  VFillContainer,
  Card,
  FootprintTypeLogo,
} from 'ui/common';

const Container = styled(VFillContainerVH)`
  background: center / cover url(${Images.Backgrounds.Blue});
`;

const IdentityCard = styled(Card)`
  width: 56vw;
  min-height: 38vh;
  overflow: hidden;
  flex-wrap: wrap;
`;

const SideImage = styled(VFillContainerV)`
  flex: 2;
  padding: 3vmin;
  background: center / cover url(${Images.Backgrounds.Black});
`;

const FormHeading = styled(VFillContainer)`
  min-height: 6rem;
  align-items: flex-start;
  justify-content: space-between;
`;

const FormContainer = styled(VFillContainerV)`
  flex: 3;
  padding: 5vmin;
  min-width: 48%
`;

export const IdentityScene = props => {

  return (

    <Container>
      <IdentityCard>

        <SideImage>

          { !!props.headline && !!props.headline.length &&

            <Heading2>
              {props.headline}
            </Heading2>
          }

          { !!props.subtext && !!props.subtext.length &&

            <Paragraph>
              {props.subtext}
            </Paragraph>
          }

        </SideImage>

        <FormContainer>

          <FormHeading>

            { props.showLogo &&

              <FootprintTypeLogo
                height={'2.5rem'}/>
            }

            { !!props.formHeading && !!props.formHeading.length &&

              <Heading6>
                {props.formHeading}
              </Heading6>
            }

          </FormHeading>

          {props.children}
        </FormContainer>

      </IdentityCard>
    </Container>
  );
};

IdentityScene.displayName = 'IdentityScene';

IdentityScene.propTypes = {
  headline: PropTypes.string,
  subtext: PropTypes.string,
  formHeading: PropTypes.string,
  showLogo: PropTypes.bool,
};

IdentityScene.defaultProps = {
  headline: undefined,
  subtext: undefined,
  formHeading: undefined,
  showLogo: true,
};
