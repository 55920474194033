import
  React
from 'react';

import
  PropTypes
from 'prop-types';

import {
  Route,
  Switch,
  HashRouter,
  BrowserRouter,
  MemoryRouter
} from 'react-router-dom';

import {
  withAuthorisation
} from 'ui/common/hoc/withAuthorisation';

import {
  NavigatorType
} from 'ui/navigation/constants';

export const Navigator = props => {

  const createRoutes = routes => {

    if (!routes) {
      return [];
    }

    let tempRoutes = [];
    let defaultRouteSet = false;
    let defaultRoute = {};
    let routeEntries = Object.entries(routes);

    for (const [key, val] of routeEntries) {

      if (!val || !val.component || !val.path || val.path.length <= 0) {
        continue;
      }

      if (!defaultRouteSet && val.default && val.path.indexOf(':') < 0) {

        defaultRoute = val;
        defaultRouteSet = true;
      }

      tempRoutes.push(

        <Route
          key={key}
          path={val.path}
          component={(val.excludeAuth && val.component) || withAuthorisation(val.component)}/>
      );
    }

    if (defaultRouteSet) {

      tempRoutes.push(

        <Route
          key={'navigator_default_route'}
          component={(defaultRoute.excludeAuth && defaultRoute.component) || withAuthorisation(defaultRoute.component)}/>
      );
    }

    return tempRoutes;
  }

  const routes = createRoutes(props.routes);
  const Router = (props.type === NavigatorType.Hash && HashRouter) || (props.type === NavigatorType.Memory && MemoryRouter) || BrowserRouter;

  return (

    <Router
      basename={props.base}>

      <Switch>
        {routes}
      </Switch>

    </Router>
  );
};

Navigator.displayName = 'Navigator';

Navigator.propTypes = {
  routes: PropTypes.object.isRequired,
  base: PropTypes.string,
  type: PropTypes.string,
};

Navigator.defaultProps = {
  routes: {},
  base: '/',
  type: NavigatorType.Browser,
};
