import
  React
from 'react';

import {
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  useLog
} from 'common';

import {
  Colors
} from 'ui/common/constants';

import {
  Card
} from 'ui/common/components/Card';

import {
  Heading5,
  Text,
} from 'ui/common/components/Type';

import {
  ToastAtom,
} from 'ui/atoms';

export const ToastType = {
  Error: 'error',
  Warning: 'warning',
  Info: 'info',
};

const ToastColor = {
  [ToastType.Error]: Colors.Red,
  [ToastType.Warning]: Colors.Yellow,
  [ToastType.Info]: Colors.Transparent,
};

const Container = styled(Card)`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  right: 0.8rem;
  height: 3rem;
  min-width: 10rem;
  transition: top 1s ease-out;
  
  top: ${props => props.top || '0.8rem'};
  border: ${props => props.border};
`;

export const Toast = props => {

  const {
    visible = props.visible,
    heading = props.heading,
    message = props.message,
    type = props.type,
    color = ToastColor[type],
  } = useRecoilValue(ToastAtom);

  return (

    <Container
      top={(!!visible && '0.8rem') || '-10rem'}
      border={`1px solid ${color}`}>

      <Heading5
        color={(type !== ToastType.Info && color) || undefined}>

        {heading}
      </Heading5>

      <Text>
        {message}
      </Text>

    </Container>
  );
};

Toast.displayName = 'Toast';

Toast.propTypes = {
  visible: PropTypes.bool.isRequired,
  heading: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
};

Toast.defaultProps = {
  visible: false,
  heading: undefined,
  message: undefined,
  type: ToastType.Warning,
};

export const useShowToast = () => {

  const setToast = useSetRecoilState(ToastAtom);

  return ({
    heading,
    message,
    type,
  }) => {

    setToast({
      visible: true,
      heading,
      message,
      type,
    });

    setTimeout(
      () => {
        setToast({
          visible: false,
          heading: undefined,
          message: undefined,
          type: undefined,
        });
      },
      5000,
    );
  };
};

export const useShowInfoToast = () => {

  const setToast = useSetRecoilState(ToastAtom);

  return ({
    heading,
    message,
  }) => {

    setToast({
      visible: true,
      heading,
      message,
      type: ToastType.Info,
    });

    setTimeout(
      () => {
        setToast({
          visible: false,
          heading: undefined,
          message: undefined,
          type: undefined,
        });
      },
      5000,
    );
  };
};

export const useShowErrorToast = context => {

  const setToast = useSetRecoilState(ToastAtom);
  const log = useLog({
    name: context
  });

  return error => {

    log.error(error);

    setToast({
      visible: true,
      heading: `${context} error`,
      message: (error && error.message) || error,
      type: ToastType.Error,
    });

    setTimeout(
      () => {
        setToast({
          visible: false,
          heading: undefined,
          message: undefined,
          type: undefined,
        });
      },
      5000,
    );
  };
};
