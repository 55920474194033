import
  React
from 'react';

import {
  useRecoilState,
} from 'recoil';

import {
  Validation,
} from 'common';

import {
  InputTypes,
  VFillContainerV,
  Button,
  Input,
  useShowErrorToast,
} from 'ui/common';

import {
  Paths
} from 'ui/navigation';

import {
  ActionContainer,
  IdentityScene,
} from 'ui/identity/components';

import {
  IdentityAtom,
} from 'ui/atoms';

import {
  IdentityApi,
  MembersApi,
} from 'api';

export const NewPassword = props => {

  const [
    identity,
    setIdentity,
  ] = useRecoilState(IdentityAtom);

  const [
    state,
    setState,
  ] = React.useState({
    password: '',
    isValid: false,
    isBusy: false,
  });

  const showErrorToast = useShowErrorToast('New password');

  const onInputChange = e => {

    if (!e || e.defaultPrevented) {
      return;
    }

    e.preventDefault();

    const value = e.target.value;

    setState(prevState => ({
      ...prevState,
      password: value,
      isValid: Validation.isValidPassword(state.password),
    }));
  };

  const onComplete = () => {

    if (!state.isValid || state.isBusy) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      isBusy: true,
    }));

    IdentityApi
      .completeNewPassword(
        identity.user,
        state.password,
        identity.userAttributes,
      )
      .then(identity => {

        setIdentity(identity);

        setState(prevState => ({
          ...prevState,
          password: '',
          isBusy: false,
        }));

        MembersApi
          .getSystemCredentials(identity.memberId)
          .then(credentials => {

            if (!credentials) {
              return;
            }

            setState(prevState => ({
              ...prevState,
              password: '',
              isBusy: false,
            }));

            const newUser = Array.isArray(credentials) && credentials.length && credentials[0] && credentials[0] && !credentials[0].CognitoUsernameChangeDate;
            props.history.push((newUser && Paths.NewUsername) || Paths.Landing);

          })
          .catch(() => props.history.push(Paths.Landing));
      })
      .catch(e => {

        setState(prevState => ({
          ...prevState,
          isBusy: false,
        }));

        showErrorToast(e);
      });
  };

  return (

    <IdentityScene
      headline={'LEAVING A FOOTPRINT FOR OTHERS TO FOLLOW'}
      subtext={'Please set a new password.'}
      formHeading={'New password'}>

      <VFillContainerV>

        <Input
          id={'new-password'}
          name={'new-password'}
          key={'new-password'}
          type={InputTypes.Password}
          readOnly={state.isBusy}
          label={'Password'}
          value={state.password}
          onChange={onInputChange}/>

      </VFillContainerV>

      <ActionContainer
        justifyContent={'flex-end'}>

        <Button
          text={'CONTINUE'}
          disabled={!state.isValid}
          busy={state.isBusy}
          onClick={onComplete}/>

      </ActionContainer>

    </IdentityScene>
  );
};

NewPassword.displayName = 'NewPassword';
