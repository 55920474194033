import {
  atom
} from 'recoil';

export const IdentityAtom = atom({
  key: 'identity',
  default: {
    destination: undefined,
    user: undefined,
    tokens: undefined,
    status: undefined,
    userAttributes: undefined,
  },
});

export const ToastAtom = atom({
  key: 'toast',
  default: {
    visible: undefined,
    heading: undefined,
    message: undefined,
    type: undefined,
  },
});

export const ActiveSideBarItemAtom = atom({
  key: 'sideBar',
  default: {}
})