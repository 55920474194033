import 
  styled 
from 'styled-components/macro';

import 
  PropTypes 
from 'prop-types';

import { 
  Colors 
} from 'ui/common/constants';

import {
  Text
} from 'ui/common/components/Type';

export const SimpleIcon = styled(Text)`
    font-family: "simple-line-icons";
    &:before {
        content: "\\${props => props.fontCharacter}";
        color: ${props => props.color || Colors.White};
        font-size: ${props => props.fontSize};
    }
`;

SimpleIcon.propTypes = {
  fontCharacter: PropTypes.string.isRequired,
  color: PropTypes.string,
}

SimpleIcon.defaultProps = {
  fontCharacter: "e05d",
  color: Colors.White,
  fontSize: "2em"
}

SimpleIcon.displayName = "SimpleIcon";