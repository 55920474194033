import
  React
from 'react';

import
  styled, {
    keyframes,
} from 'styled-components/macro';

import {
  Colors,
} from 'ui/common/constants';

import {
  HFillContainerVH,
} from 'ui/common/components/Containers';

const ScaleFrames = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
`;

const Indicator = styled.span `
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${Colors.White};
  border-radius: 100%;
  display: inline-block;
  animation: ${ScaleFrames} 1.2s infinite ease-in-out both;
  
  animation-delay: ${props => props.delay};
`;

const Container = styled(HFillContainerVH)`
  position: ${props => props.position || 'relative'}
`;

export const BounceIndicator = props =>

  <Container
    {...props}>

    <Indicator
      delay={'0s'}/>

    <Indicator
      delay={'0.16s'}/>

    <Indicator
      delay={'0.32s'}/>

  </Container>
;

BounceIndicator.displayName = 'BounceIndicator';
